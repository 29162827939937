<template>
    <div class="p-grid">
        <Toast />
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.changePassword') }}</h5>
                <div class="p-field p-grid">
                    <label for="sportId" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('login.oldPassword') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportId" type="password" v-model="user.oldPassword" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="sportName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('login.newPassword') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportName" type="password" v-model="user.newPassword" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('login.retypeNewPassword') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="displayName" type="password" v-model="user.retypePassword" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            user: {
                newPassword: '',
                retypePassword: '',
                oldPassword: '',
            },
        };
    },
    mounted() {},
    methods: {
        submitForm() {
            if (this.notNullOrEmptyField(this.user.newPassword) && this.notNullOrEmptyField(this.user.oldPassword) && this.notNullOrEmptyField(this.user.retypePassword)) {
                if (this.user.newPassword !== this.user.retypePassword) {
                    this.showToast('error', this.$t('login.passwordMatch'));
                } else {
                    let loginUser = {
                        oldPassword: this.user.oldPassword,
                        newPassword: this.user.newPassword,
                        username: this.$store.getters['user/username'],
                    };
                    this.BoApi.changeUserPassword(loginUser)
                        .then(() => {
                            this.showToast('success', this.$t('alerts.okAction'));
                        })
                        .catch((error) => {
                            this.checkForUnauthorizedError(error);
                            this.showToast('error', error.response.data.errorCode);
                        });
                }
            } else {
                this.showToast('error', this.$t('forms.mandatoryFieldsMessage'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
    },
};
</script>
